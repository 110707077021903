import React, { useState, useEffect } from "react";
import moment from "moment";
import Navbar from "../../navbar/navbar";
import { CiCirclePlus } from "react-icons/ci";
import { IoIosCloudUpload } from "react-icons/io";
import FileUpload from "../employee_quick_onboard/upload_files";
import { AiOutlineClose } from "react-icons/ai";

const Emp_edit = ({ employee, onClose }) => {
  const employee_no = employee.employee_no;

  const [currentDate] = useState(moment().format("MMMM Do YYYY"));
  const [fieldsEdited, setFieldsEdited] = useState({});

  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [employeeData, setEmployeeData] = useState({
    employee_no: employee.employee_no || "",
    employee_fullname: employee.employee_fullname || "",
    employee_name_initial: employee.employee_name_initial || "",
    employee_calling_name: employee.employee_calling_name || "",
    employee_nic: employee.employee_nic || "",
    employee_dob: employee.employee_dob || "",
    employee_gender: employee.employee_gender || "Male",
    employee_marital_status: employee.employee_marital_status || "Single",
    employee_contact_no: employee.employee_contact_no || "",
    employee_permanent_address: employee.employee_permanent_address || "",
    employee_temporary_address: employee.employee_temporary_address || "",
    employee_email: employee.employee_email || "",
    personal_email: employee.personal_email || "",
    designated_mail: employee.designated_mail || [], // Initialize as an array
    employee_dependent_details: employee.employee_dependent_details || [],
    date_of_appointment: employee.date_of_appointment || "",
    employee_basic_salary: employee.employee_basic_salary || "",
    employee_active_status: employee.employee_active_status || "ACTIVE",
    employee_account_no: employee.employee_account_no || "",
    employee_account_name: employee.employee_account_name || "",
    employee_bank_name: employee.employee_bank_name || "",
    employee_branch_name: employee.employee_branch_name || "",
    department_designation_id: "",
    supervisor_fullname: employee.supervisor
      ? employee.supervisor.supervisor_fullname
      : "",
    timetable_name: employee.timetable ? employee.timetable.TimetableName : "",
  });

  const [timetables, setTimetables] = useState([]);
  const [newProfessionalQualifications, setNewProfessionalQualifications] =
    useState([]);
  const [newEducationalQualifications, setNewEducationalQualifications] =
    useState([]);

  const [selectedDepartment, setSelectedDepartment] = useState(
    employeeData.department || ""
  ); // Default to the employee's department
  const [filteredDesignations, setFilteredDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [nextOfKinSections, setNextOfKinSections] = useState([{}]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [files, setFiles] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [errors, setErrors] = useState({});
  const [professionalQualifications, setProfessionalQualifications] = useState([
    { title: "", year: "", files: [] },
  ]);
  const [educationalQualifications, setEducationalQualifications] = useState([
    { degree: "", year: "", files: [] },
  ]);
  const [selectedSupervisorId, setSelectedSupervisorId] = useState(
    employee.supervisor ? employee.supervisor.supervisor_id : ""
  );
  const [selectedTimetableId, setSelectedTimetableId] = useState(
    employee.timetable ? employee.timetable.TimetableID : ""
  );

  const [isActive, setIsActive] = useState(
    employeeData.employee_active_status === "ACTIVE"
  );

  useEffect(() => {
    setIsActive(employeeData.employee_active_status === "ACTIVE");
  }, [employeeData]);

  const handleAddQualification = () => {
    const newEntry = { title: "", year: "", files: [] };
    setProfessionalQualifications((prev) => [...prev, newEntry]);
    setNewProfessionalQualifications((prev) => [...prev, newEntry]);
  };

  useEffect(() => {
    if (employee.department_designation_id) {
      setEmployeeData((prevData) => ({
        ...prevData,
        department_designation_id: employee.department_designation_id,
      }));
    }
  }, [employee.department_designation_id]);

  const handleAddEducation = () => {
    const newEntry = { degree: "", year: "", files: [] };
    setEducationalQualifications((prev) => [...prev, newEntry]);
    setNewEducationalQualifications((prev) => [...prev, newEntry]);
  };

  const handleSubmitNewQualifications = async () => {
    const formDataProf = new FormData();
    newProfessionalQualifications.forEach((qualification, index) => {
      formDataProf.append(
        `professionalDetails[${index}][qualification_name]`,
        qualification.title
      );
      formDataProf.append(
        `professionalDetails[${index}][completed_year]`,
        qualification.year
      );
      qualification.files.forEach((file) =>
        formDataProf.append(`professionalDetails[${index}][files]`, file)
      );
    });
    formDataProf.append("employee_no", employee_no);

    const formDataEdu = new FormData();
    newEducationalQualifications.forEach((qualification, index) => {
      formDataEdu.append(
        `educationDetails[${index}][qualification_name]`,
        qualification.degree
      );
      formDataEdu.append(
        `educationDetails[${index}][completed_year]`,
        qualification.year
      );
      qualification.files.forEach((file) =>
        formDataEdu.append(`educationDetails[${index}][file]`, file)
      );
    });
    formDataEdu.append("employee_no", employee_no);

    try {
      // Professional Qualifications POST
      if (newProfessionalQualifications.length > 0) {
        const responseProf = await fetch(
          `${API_URL}/v1/hris/employees/uploadEmployeeProfessionalFiles`,
          {
            method: "POST",
            body: formDataProf,
          }
        );
        const resultProf = await responseProf.json();
        if (!resultProf.success)
          throw new Error("Failed to upload professional qualifications");
      }

      // Educational Qualifications POST
      if (newEducationalQualifications.length > 0) {
        const responseEdu = await fetch(
          `${API_URL}/v1/hris/employees/uploadQualification`,
          {
            method: "POST",
            body: formDataEdu,
          }
        );
        const resultEdu = await responseEdu.json();
        if (!resultEdu.success)
          throw new Error("Failed to upload educational qualifications");
      }

      setSuccessMessage("New qualifications added successfully.");
      setNewProfessionalQualifications([]);
      setNewEducationalQualifications([]);
    } catch (error) {
      console.error("Error submitting new qualifications:", error);
      setSuccessMessage("Failed to add new qualifications.");
    }
  };

  const handleActiveToggle = () => {
    setIsActive(!isActive);
    setEmployeeData((prevData) => ({
      ...prevData,
      employee_active_status: !isActive ? "ACTIVE" : "INACTIVE",
    }));
  };

  const handleRemoveQualification = (index) => {
    setProfessionalQualifications(
      professionalQualifications.filter((_, i) => i !== index)
    );
  };
  const handleDesignatedMailChange = (inputValue) => {
    const emails = inputValue.split(",").map((email) => email.trim());

    // Validate emails
    const isValid = emails.every(
      (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) || email === ""
    );

    // Update state regardless of validity to allow typing
    setEmployeeData((prevData) => ({
      ...prevData,
      designated_mail: emails,
    }));

    // Update validation errors
    setErrors((prevErrors) => ({
      ...prevErrors,
      designated_mail: isValid
        ? null
        : "Please enter valid email addresses separated by commas.",
    }));
  };

  const handleEducationChange = (index, event) => {
    const { name, value, files } = event.target;
    const updatedQualifications = educationalQualifications.map(
      (qualification, i) =>
        i === index
          ? {
              ...qualification,
              [name]: files ? files[0] : value, // Attach the file if it exists
            }
          : qualification
    );
    setEducationalQualifications(updatedQualifications);
  };

  const handleRemoveEducation = (index) => {
    setEducationalQualifications(
      educationalQualifications.filter((_, i) => i !== index)
    );
  };

  const handleFileChangeEducation = (index, event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    setEducationalQualifications((prevQualifications) =>
      prevQualifications.map((qualification, i) =>
        i === index
          ? { ...qualification, files } // Update the files for the specific qualification
          : qualification
      )
    );
  };

  const handleFileChange = (index, event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    setProfessionalQualifications((prevQualifications) =>
      prevQualifications.map((qualification, i) =>
        i === index
          ? { ...qualification, files } // Update the files for the specific qualification
          : qualification
      )
    );
  };

  const handleDependentChange = (index, e) => {
    const { name, value } = e.target;
    const updatedDependents = employeeData.employee_dependent_details.map(
      (dep, i) => (i === index ? { ...dep, [name]: value } : dep)
    );
    setEmployeeData((prevState) => ({
      ...prevState,
      employee_dependent_details: updatedDependents,
    }));
  };

  const API_URL = process.env.REACT_APP_FRONTEND_URL;

  // Fetch designations and departments on component load
  useEffect(() => {
    const fetchDesignations = async () => {
      try {
        const response = await fetch(
          `${API_URL}/v1/hris/designations/getdesignation`
        );
        const result = await response.json();

        // Extract unique departments and set them
        const departmentList = Array.from(
          new Set(result.map((item) => item.department))
        );
        setDepartments(departmentList);
        setDesignations(result);

        // Set default department from employee data
        if (employeeData.department) {
          setSelectedDepartment(employeeData.department);

          // Filter designations based on employee's department
          const filtered = result.filter(
            (designation) => designation.department === employeeData.department
          );
          setFilteredDesignations(filtered);
        }
      } catch (error) {
        console.error("Error fetching designations:", error);
      }
    };

    fetchDesignations();
  }, [employeeData.department, API_URL]);

  const handleDepartmentChange = (e) => {
    const selectedDept = e.target.value;
    setSelectedDepartment(selectedDept);

    // Reset designation and filter designations for the selected department
    setEmployeeData((prevData) => ({
      ...prevData,
      department: selectedDept,
      designation: "", // Reset designation
      department_designation_id: null, // Reset department_designation_id
    }));

    const filtered = designations.filter(
      (designation) => designation.department === selectedDept
    );
    setFilteredDesignations(filtered);
  };

  const handleDesignationChange = (e) => {
    const selectedDesignation = e.target.value;
    const selectedDesignationData = designations.find(
      (designation) => designation.designation === selectedDesignation
    );

    setEmployeeData((prevData) => ({
      ...prevData,
      designation: selectedDesignation,
      department_designation_id: selectedDesignationData
        ? selectedDesignationData.id
        : null,
    }));
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const [originalDepartmentDesignationId, setOriginalDepartmentDesignationId] =
    useState(null);
  const [designationEdited, setDesignationEdited] = useState(false);

  // Fetch employee data

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await fetch(
          `${API_URL}/v1/hris/employees/getemployeebyid?employee_no=${employee_no}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch employee data");
        }
        const data = await response.json();

        // Ensure designated_mail is always an array
        const designatedMail = Array.isArray(data.designated_mail)
          ? data.designated_mail
          : data.designated_mail
          ? [data.designated_mail]
          : [];

        setEmployeeData({
          ...data,
          designated_mail: designatedMail, // Safe array handling
          personal_email: data.personal_email || "",
          professional_qualifications: data.professional_qualifications || [],
          educational_qualifications: data.educational_qualifications || [],
          employee_dependent_details: data.employee_dependent_details || [],
          employee_upload_files: data.employee_upload_files || [],
        });

        // Handle timetable and supervisor if available
        setSelectedTimetableId(data.timetable?.TimetableID || "");
        setSelectedSupervisorId(data.supervisor?.supervisor_id || "");
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchEmployeeData();
  }, [employee_no, API_URL]);

  // Fetch uploaded files
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await fetch(
          `${API_URL}/download?employee_no=${employee_no}`
        );
        const data = await response.json();

        if (data.success) {
          setFiles(data.files);
        } else {
          console.error("Error fetching files:", data.error);
        }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchFiles();
  }, [employee_no, API_URL]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "designated_mail") {
      // Validate email input
      const emails = value.split(",").map((email) => email.trim());
      const isValid = emails.every((email) =>
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
      );

      setEmployeeData((prevData) => ({
        ...prevData,
        designated_mail: value, // Store it as a comma-separated string for the input
      }));

      setErrors((prevErrors) => ({
        ...prevErrors,
        designated_mail: isValid
          ? null
          : "Please enter valid email addresses separated by commas.",
      }));
    } else {
      setEmployeeData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setFieldsEdited((prevFields) => ({
        ...prevFields,
        [name]: true,
      }));
    }
  };

  // Handle file upload for main files
  const handleFileUpload = async (employeeNo) => {
    const formData = new FormData();
    uploadedFiles.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("employee_no", employeeNo);

    try {
      const response = await fetch(
        `${API_URL}/v1/hris/employees/uploadEmployeeFiles`,
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();
      if (result.success) {
        setSuccessMessage("Files uploaded successfully");
        setUploadedFiles([]);
      } else {
        setSuccessMessage("Failed to upload files");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setSuccessMessage("Error uploading files");
    }
  };

  const handleProfessionalQualificationsUpload = async (employeeNo) => {
    const formData = new FormData();

    formData.append("employee_no", employeeNo);

    professionalQualifications.forEach((qualification, index) => {
      formData.append(
        `professionalDetails[${index}][qualification_name]`,
        qualification.title
      );
      formData.append(
        `professionalDetails[${index}][completed_year]`,
        qualification.year
      );

      if (qualification.file) {
        formData.append(
          `professionalDetails[${index}][files]`,
          qualification.file
        );
      }
    });

    try {
      const response = await fetch(
        `${API_URL}/v1/hris/employees/uploadEmployeeProfessionalFiles`,
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();
      if (result.success) {
        setSuccessMessage("Professional qualifications uploaded successfully.");
      } else {
        setSuccessMessage("Failed to upload professional qualifications.");
      }
    } catch (error) {
      console.error("Error uploading professional qualifications:", error);
      setSuccessMessage("Error uploading professional qualifications.");
    }
  };

  // Fetch supervisors and timetables
  useEffect(() => {
    const fetchSupervisors = async () => {
      try {
        const response = await fetch(
          `${API_URL}/v1/hris/supervisors/getSupervisors`
        );
        const result = await response.json();
        setSupervisors(result);
      } catch (error) {
        console.error("Error fetching supervisors:", error);
      }
    };

    const fetchTimetables = async () => {
      try {
        const response = await fetch(
          `${API_URL}/v1/hris/timetable/gettimetable`
        );
        const result = await response.json();
        setTimetables(result);
      } catch (error) {
        console.error("Error fetching timetables:", error);
      }
    };

    fetchSupervisors();
    fetchTimetables();
  }, [API_URL]);

  // Handle supervisor change
  const handleSupervisorChange = (e) => {
    const supervisorId = e.target.value; // This is now an ID, not a name.
    setSelectedSupervisorId(supervisorId);

    // Optionally update supervisor name in the state if needed elsewhere in the UI
    const selectedSupervisor = supervisors.find(
      (sup) => sup.id.toString() === supervisorId
    );
    if (selectedSupervisor) {
      setEmployeeData((prevData) => ({
        ...prevData,
        supervisor_fullname: selectedSupervisor.supervisor_fullname,
      }));
    }
  };

  // Handle timetable change
  const handleTimetableChange = (e) => {
    const timetableId = e.target.value;
    setSelectedTimetableId(timetableId);

    const selectedTimetable = timetables.find(
      (timetable) => timetable.TimetableID === parseInt(timetableId, 10)
    );

    setEmployeeData((prevData) => ({
      ...prevData,
      timetable_name: selectedTimetable ? selectedTimetable.TimetableName : "",
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Ensure designated_mail is an array
    const validDesignatedMail = Array.isArray(employeeData.designated_mail)
      ? employeeData.designated_mail
      : [];
    try {
      // Check if personal_email has changed
      const updatedPersonalEmail =
        employeeData.personal_email !== null &&
        employeeData.personal_email.trim() !== ""
          ? employeeData.personal_email
          : employee.personal_email; // Retain original value if unchanged

      const putData = {
        editor: "admin_user",
        employee_no: employeeData.employee_no,
        employee_fullname: employeeData.employee_fullname,
        employee_name_initial: employeeData.employee_name_initial,
        employee_calling_name: employeeData.employee_calling_name,
        employee_nic: employeeData.employee_nic,
        employee_dob: employeeData.employee_dob,
        employee_gender: employeeData.employee_gender,
        employee_marital_status: employeeData.employee_marital_status,
        employee_contact_no: employeeData.employee_contact_no,
        employee_permanent_address: employeeData.employee_permanent_address,
        employee_temporary_address: employeeData.employee_temporary_address,
        employee_email: employeeData.employee_email,
        employee_dependent_details: employeeData.employee_dependent_details,
        date_of_appointment: employeeData.date_of_appointment,
        employee_basic_salary: employeeData.employee_basic_salary,
        employee_active_status: employeeData.employee_active_status,
        employee_account_no: employeeData.employee_account_no,
        employee_account_name: employeeData.employee_account_name,
        employee_bank_name: employeeData.employee_bank_name,
        employee_branch_name: employeeData.employee_branch_name,
        department_designation_id: employeeData.department_designation_id, // Send selected department_designation_id
        timetable_id: selectedTimetableId, // Use the selected timetable ID
        personal_email: updatedPersonalEmail, // Use the updated or original value
        designated_mail: Array.isArray(employeeData.designated_mail)
          ? employeeData.designated_mail
          : [],
      };

      console.log("Submitting the following data:", putData);

      // Send the PUT request to update employee data
      const response = await fetch(
        `${API_URL}/v1/hris/employees/updateemployee`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(putData),
        }
      );

      const result = await response.json();

      if (result.success) {
        setSuccessMessage("Employee updated successfully.");
        console.log("Employee data updated successfully.");

        // Submit new qualifications after updating the main employee data
        await handleSubmitNewQualifications();

        // Reset the editable state and close the form after a delay
        setIsEditable(false);
        setTimeout(() => {
          setSuccessMessage("");
          onClose(); // Close the edit modal
        }, 2000);
      } else {
        setSuccessMessage("Failed to update employee.");
        console.error(
          "Failed to update employee:",
          result.error || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error updating employee:", error);
      setSuccessMessage("Error updating employee.");
    }
  };

  // Handle closing the popup
  const handleClosepopup = () => {
    onClose();
  };

  // Handle adding a new Next of Kin section
  const handleAddSection = () => {
    setNextOfKinSections([...nextOfKinSections, {}]);
    setEmployeeData((prevState) => ({
      ...prevState,
      employee_dependent_details: [...prevState.employee_dependent_details, {}],
    }));
  };

  // Handle removing an uploaded file
  const handleRemoveFile = (index) => {
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(updatedFiles);
  };

  // Handle opening the modal for file upload
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="mx-10 mt-5">
      <div className="mt-6 flex justify-between">
        <div className="">
          <p className="text-[30px] font-semibold">Employee Edit</p>

          <p>
            Employee Management, Employee Edit Details{" "}
            <span className="text-primary_purple">Employee Edit</span>
          </p>
        </div>

        <button
          onClick={handleClosepopup}
          className="text-red-500 hover:text-gray-700 transition duration-300"
        >
          Close
        </button>
      </div>
      <button
        onClick={() => setIsEditable(!isEditable)}
        className="bg-[#8764A0] p-2 rounded-md text-white mb-4  w-[100px] ml-[90%]"
      >
        {isEditable ? "Cancel" : "Edit"}
      </button>
      <form onSubmit={handleSubmit} className="max-w-6xl p-8">
        <div className="flex justify-between items-center mb-3">
          <h1 className="text-[30px] font-bold mb-8">Personal Details</h1>
          <div>
            {isEditable && (
              <label
                className="switch"
                style={{
                  position: "relative",
                  display: "inline-block",
                  width: "3.5em",
                  height: "2em",
                  fontSize: "17px",
                }}
              >
                <input
                  type="checkbox"
                  checked={isActive}
                  onChange={handleActiveToggle}
                  style={{ opacity: 0, width: 0, height: 0 }}
                />
                <span
                  className="slider"
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    inset: 0,
                    background: isActive ? "#b84fce" : "#d4acfb",
                    borderRadius: "50px",
                    transition: "all 0.4s cubic-bezier(0.23, 1, 0.320, 1)",
                  }}
                ></span>
                <span
                  className="slider-circle"
                  style={{
                    position: "absolute",
                    height: isActive ? "2em" : "1.4em",
                    width: isActive ? "2em" : "1.4em",
                    left: isActive ? "1.6em" : "0.3em",
                    bottom: isActive ? "0" : "0.3em",
                    backgroundColor: "white",
                    borderRadius: "50px",
                    boxShadow: "0 0px 20px rgba(0,0,0,0.4)",
                    transition:
                      "all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                  }}
                ></span>
              </label>
            )}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-y-[30px] gap-x-[60px] text-[20px]">
          <div>
            <label className="block text-gray-700">Employee ID</label>
            <input
              type="text"
              name="employee_no"
              value={employeeData.employee_no}
              onChange={handleChange}
              readOnly
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">
              Employee Name (Full Name)
            </label>
            <input
              type="text"
              name="employee_fullname"
              value={employeeData.employee_fullname}
              onChange={handleChange}
              readOnly={!isEditable}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Name with Initials</label>
            <input
              type="text"
              name="employee_name_initial"
              value={employeeData.employee_name_initial}
              onChange={handleChange}
              readOnly={!isEditable}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-y-[30px] gap-x-[60px] text-[20px] mt-10">
          <div>
            <label className="block text-gray-700">Calling Name</label>
            <input
              type="text"
              name="employee_calling_name"
              value={employeeData.employee_calling_name}
              onChange={handleChange}
              readOnly={!isEditable}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">
              NIC (National Identity Card)
            </label>
            <input
              type="text"
              name="employee_nic"
              value={employeeData.employee_nic}
              onChange={handleChange}
              readOnly={!isEditable}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Date of Birth</label>
            <input
              type="date"
              name="employee_dob"
              value={
                employeeData.employee_dob
                  ? moment(employeeData.employee_dob).format("YYYY-MM-DD")
                  : ""
              }
              onChange={handleChange}
              readOnly={!isEditable}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-y-[30px] gap-x-[60px] text-[20px] mt-10">
          <div>
            <label className="block text-gray-700">Gender</label>
            <select
              name="employee_gender"
              value={employeeData.employee_gender}
              onChange={handleChange}
              disabled={!isEditable}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
            >
              <option>Male</option>
              <option>Female</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700">Marital Status</label>
            <select
              name="employee_marital_status"
              value={employeeData.employee_marital_status}
              onChange={handleChange}
              disabled={!isEditable}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-purple-500"
            >
              <option>Married</option>
              <option>Single</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700">Contact Number</label>
            <input
              type="text"
              name="employee_contact_no"
              value={employeeData.employee_contact_no}
              onChange={handleChange}
              readOnly={!isEditable}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-y-[30px] gap-x-[60px] text-[20px] mt-10">
          <div>
            <label className="block text-gray-700">Permanent Address</label>
            <input
              type="text"
              name="employee_permanent_address"
              value={employeeData.employee_permanent_address}
              onChange={handleChange}
              readOnly={!isEditable}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Temporary Address</label>
            <input
              type="text"
              name="employee_temporary_address"
              value={employeeData.employee_temporary_address}
              onChange={handleChange}
              readOnly={!isEditable}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Email Address</label>
            <input
              type="email"
              name="employee_email"
              value={employeeData.employee_email}
              onChange={handleChange}
              readOnly={!isEditable}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Personal Email</label>
            {!isEditable ? (
              <p className="w-full border border-gray-300 p-2 rounded mt-2">
                {employeeData.personal_email || "No personal email provided"}
              </p>
            ) : (
              <input
                type="email"
                name="personal_email"
                value={employeeData.personal_email}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded mt-2"
              />
            )}
          </div>

          <div>
            <label className="block text-gray-700">Designated Emails</label>
            {!isEditable ? (
              // Display the designated emails as a comma-separated string in read-only mode
              <div className="w-full border border-gray-300 p-2 rounded mt-2">
                {Array.isArray(employeeData.designated_mail) &&
                employeeData.designated_mail.length > 0
                  ? employeeData.designated_mail.join(", ")
                  : "No designated emails available"}
              </div>
            ) : (
              // Editable input for comma-separated emails
              <input
                name="designated_mail"
                value={employeeData.designated_mail.join(", ")} // Display the emails as a comma-separated string
                onChange={(e) => handleDesignatedMailChange(e.target.value)}
                placeholder="Enter emails separated by commas"
                className="w-full border border-gray-300 p-2 rounded mt-2"
              />
            )}
            {errors.designated_mail && (
              <p className="text-red-500">{errors.designated_mail}</p>
            )}
          </div>
        </div>
        {nextOfKinSections.map((_, index) => (
          <React.Fragment key={index}>
            <h1 className="text-[30px] font-bold col-span-3 mt-8">
              Next Of Kin {index + 1} Details
            </h1>
            <div className="grid grid-cols-1 gap-y-[30px] text-[20px]">
              <div>
                <label className="block text-gray-700">Relationship</label>
                <select
                  name="employee_dependent_relationship"
                  value={
                    employeeData.employee_dependent_details[index]
                      ?.employee_dependent_relationship || ""
                  }
                  onChange={(e) => handleDependentChange(index, e)}
                  disabled={!isEditable}
                  className="w-full border border-gray-300 p-2 rounded mt-2"
                >
                  <option value="Spouse">Spouse</option>
                  <option value="Child">Child</option>
                  <option value="Parent">Parent</option>
                  <option value="Sibling">Sibling</option>
                  <option value="Guardian">Guardian</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div>
                <label className="block text-gray-700">Name</label>
                <input
                  type="text"
                  name="employee_dependent_name"
                  value={
                    employeeData.employee_dependent_details[index]
                      ?.employee_dependent_name || ""
                  }
                  onChange={(e) => handleDependentChange(index, e)}
                  readOnly={!isEditable}
                  className="w-full border border-gray-300 p-2 rounded mt-2"
                />
              </div>
              <div>
                <label className="block text-gray-700">
                  NIC (National Identity Card)
                </label>
                <input
                  type="text"
                  name="employee_dependent_nic"
                  value={
                    employeeData.employee_dependent_details[index]
                      ?.employee_dependent_nic || ""
                  }
                  onChange={(e) => handleDependentChange(index, e)}
                  readOnly={!isEditable}
                  className="w-full border border-gray-300 p-2 rounded mt-2"
                />
              </div>
              <div>
                <label className="block text-gray-700">
                  DoB (Date of Birth)
                </label>
                <input
                  type="date"
                  name="employee_dependent_dob"
                  value={
                    employeeData.employee_dependent_details[index]
                      ?.employee_dependent_dob
                      ? moment(
                          employeeData.employee_dependent_details[index]
                            .employee_dependent_dob
                        ).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => handleDependentChange(index, e)}
                  readOnly={!isEditable}
                  className="w-full border border-gray-300 p-2 rounded mt-2"
                />
              </div>
            </div>
          </React.Fragment>
        ))}
        {employeeData.employee_dependent_details.length < 5 && (
          <div className="mt-8">
            <button
              type="button"
              onClick={handleAddSection}
              className="p-3 border border-[#8764A0] rounded-[25px] text-[#8764A0]"
            >
              <div className="flex gap-2 items-center">
                <div>Add Another</div>
                <CiCirclePlus />
              </div>
            </button>
          </div>
        )}
        <h1 className="text-[30px] font-bold mb-8 mt-10">Employment Details</h1>
        <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
          <div>
            <label className="block text-gray-700">Department</label>
            <select
              name="department"
              value={selectedDepartment}
              onChange={handleDepartmentChange}
              disabled={!isEditable}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            >
              <option value="">Select Department</option>
              {departments.map((department, index) => (
                <option key={index} value={department}>
                  {department}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700">Designation</label>
            <select
              name="designation"
              value={employeeData.designation}
              onChange={handleDesignationChange}
              disabled={!isEditable || !selectedDepartment}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            >
              <option value="">Select Designation</option>
              {filteredDesignations.map((designation) => (
                <option key={designation.id} value={designation.designation}>
                  {designation.designation}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700">Date of Appointment</label>
            <input
              type="date"
              name="date_of_appointment"
              value={
                employeeData.date_of_appointment
                  ? moment(employeeData.date_of_appointment).format(
                      "YYYY-MM-DD"
                    )
                  : ""
              }
              onChange={handleChange}
              readOnly={!isEditable}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Basic Salary</label>
            <input
              type="text"
              name="employee_basic_salary"
              value={employeeData.employee_basic_salary}
              onChange={handleChange}
              readOnly={!isEditable}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>

          <div>
            <label className="block text-gray-700">Supervisor</label>
            {!isEditable ? (
              // Show supervisor name when not in edit mode
              <p className="p-2 border border-gray-300 rounded">
                {employee.supervisor?.supervisor_fullname ||
                  "No supervisor assigned"}
              </p>
            ) : (
              // Show dropdown with the current supervisor pre-selected when in edit mode
              <select
                name="supervisor_id"
                value={selectedSupervisorId}
                onChange={handleSupervisorChange}
                className="w-full border border-gray-300 p-2 rounded"
              >
                <option value="">Select Supervisor</option>
                {supervisors.map((supervisor) => (
                  <option key={supervisor.id} value={supervisor.id}>
                    {supervisor.supervisor_fullname}
                  </option>
                ))}
              </select>
            )}
          </div>

          <div>
            <label className="block text-gray-700">Timetable</label>
            {!isEditable ? (
              <p className="p-2 border border-gray-300 rounded">
                {employeeData?.timetable?.TimetableName ||
                  "No timetable assigned"}
              </p>
            ) : (
              <select
                name="timetable_id"
                value={selectedTimetableId || ""}
                onChange={handleTimetableChange}
                className="w-full border border-gray-300 p-2 rounded"
              >
                <option value="">Select Timetable</option>
                {timetables.map((timetable) => (
                  <option
                    key={timetable.TimetableID}
                    value={timetable.TimetableID}
                  >
                    {timetable.TimetableName}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>

        {(professionalQualifications?.length > 0
          ? professionalQualifications
          : [{}]
        ).map((qualification, index) => (
          <React.Fragment key={index}>
            <h1 className="text-[30px] font-bold col-span-3 mt-8">
              Professional Qualification
            </h1>
            {qualification.title || isEditable ? (
              <div className="grid grid-cols-1 gap-y-[30px] text-[20px] relative">
                {index > 0 && isEditable && (
                  <button
                    type="button"
                    onClick={() => handleRemoveQualification(index)}
                    className="absolute top-0 right-0 mt-2 mr-2 text-red-500"
                  >
                    <AiOutlineClose size={24} />
                  </button>
                )}
                <div>
                  <label className="block text-gray-700">
                    Qualification Name
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={qualification?.title || ""}
                    onChange={(e) =>
                      setProfessionalQualifications((prev) =>
                        prev.map((q, i) =>
                          i === index ? { ...q, title: e.target.value } : q
                        )
                      )
                    }
                    className="w-full border border-gray-300 p-2 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">
                    Year of Completion
                  </label>
                  <input
                    type="number"
                    name="year"
                    value={qualification?.year || ""}
                    onChange={(e) =>
                      setProfessionalQualifications((prev) =>
                        prev.map((q, i) =>
                          i === index ? { ...q, year: e.target.value } : q
                        )
                      )
                    }
                    className="w-full border border-gray-300 p-2 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Upload File</label>
                  <input
                    type="file"
                    multiple
                    onChange={(e) => handleFileChange(index, e)}
                    handleProfessionalQualificationsUpload
                    className="w-full border border-gray-300 p-2 rounded"
                  />
                </div>
                {/* Display Selected Files */}
                {qualification.files?.length > 0 && (
                  <div className="mt-3">
                    <h3 className="text-lg font-bold mb-2">Selected Files</h3>
                    {qualification.files.map((file, fileIndex) => (
                      <div
                        key={fileIndex}
                        className="flex items-center justify-between p-2 border rounded-lg mb-2 bg-yellow-100"
                      >
                        <span>{file.name}</span>
                        <button
                          type="button"
                          onClick={() =>
                            setProfessionalQualifications((prev) =>
                              prev.map((q, i) =>
                                i === index
                                  ? {
                                      ...q,
                                      files: q.files.filter(
                                        (_, fIndex) => fIndex !== fileIndex
                                      ),
                                    }
                                  : q
                              )
                            )
                          }
                          className="bg-red-500 text-white px-3 py-1 rounded"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : null}
          </React.Fragment>
        ))}

        {isEditable &&
          Array.isArray(professionalQualifications) &&
          professionalQualifications.length < 5 && (
            <div className="mt-8">
              <button
                type="button"
                onClick={handleAddQualification}
                className="p-3 border border-[#8764A0] rounded-[25px] text-[#8764A0]"
              >
                <div className="flex gap-2 items-center">
                  <div>Add Another Qualification</div>
                  <CiCirclePlus />
                </div>
              </button>
            </div>
          )}
        {(educationalQualifications?.length > 0
          ? educationalQualifications
          : [{}]
        ).map((qualification, index) => (
          <React.Fragment key={index}>
            <h1 className="text-[30px] font-bold col-span-3 mt-8">
              Educational Qualification
            </h1>
            {qualification.degree || isEditable ? (
              <div className="grid grid-cols-1 gap-y-[30px] text-[20px] relative">
                {index > 0 && isEditable && (
                  <button
                    type="button"
                    onClick={() => handleRemoveEducation(index)}
                    className="absolute top-0 right-0 mt-2 mr-2 text-red-500"
                  >
                    <AiOutlineClose size={24} />
                  </button>
                )}
                <div>
                  <label className="block text-gray-700">
                    Degree/Qualification
                  </label>
                  <input
                    type="text"
                    name="degree"
                    value={qualification?.degree || ""}
                    onChange={(e) => handleEducationChange(index, e)}
                    readOnly={!isEditable}
                    className="w-full border border-gray-300 p-2 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">
                    Year of Completion
                  </label>
                  <input
                    type="number"
                    name="year"
                    value={qualification?.year || ""}
                    onChange={(e) => handleEducationChange(index, e)}
                    readOnly={!isEditable}
                    className="w-full border border-gray-300 p-2 rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700">Uploaded File</label>
                  {qualification?.file_path ? (
                    <a
                      href={`${API_URL}/${qualification.file_path}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-2 text-blue-500 underline"
                    >
                      <span>
                        <img
                          src="path-to-pdf-icon.png" // Ensure this path is correct
                          alt="PDF Icon"
                          className="h-8 w-8"
                        />
                      </span>
                      {qualification.qualification_name || "View File"}
                    </a>
                  ) : (
                    <p className="text-gray-500">No file uploaded</p>
                  )}
                </div>
                {isEditable && (
                  <div>
                    <label className="block text-gray-700">
                      Upload New File
                    </label>
                    <input
                      type="file"
                      onChange={(e) => handleFileChangeEducation(index, e)}
                      className="w-full border border-gray-300 p-2 rounded"
                    />
                  </div>
                )}
              </div>
            ) : null}
          </React.Fragment>
        ))}

        {isEditable &&
          Array.isArray(educationalQualifications) &&
          educationalQualifications.length < 5 && (
            <div className="mt-8">
              <button
                type="button"
                onClick={handleAddEducation}
                className="p-3 border border-[#8764A0] rounded-[25px] text-[#8764A0]"
              >
                <div className="flex gap-2 items-center">
                  <div>Add Another Education</div>
                  <CiCirclePlus />
                </div>
              </button>
            </div>
          )}

        <h1 className="text-[30px] font-bold mb-8 mt-10">Bank Details</h1>
        <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
          <div>
            <label className="block text-gray-700">Account Number</label>
            <input
              type="text"
              name="employee_account_no"
              value={employeeData.employee_account_no}
              onChange={handleChange}
              readOnly={!isEditable}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Account Name</label>
            <input
              type="text"
              name="employee_account_name"
              value={employeeData.employee_account_name}
              onChange={handleChange}
              readOnly={!isEditable}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Bank Name</label>
            <input
              type="text"
              name="employee_bank_name"
              value={employeeData.employee_bank_name}
              onChange={handleChange}
              readOnly={!isEditable}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
          <div>
            <label className="block text-gray-700">Bank Branch</label>
            <input
              type="text"
              name="employee_branch_name"
              value={employeeData.employee_branch_name}
              onChange={handleChange}
              readOnly={!isEditable}
              className="w-full border border-gray-300 p-2 rounded mt-2"
            />
          </div>
        </div>
        <h1 className="text-[30px] font-bold mb-8 mt-10">Personal Documents</h1>
        {isEditable && (
          <button
            type="button"
            onClick={handleOpenModal}
            className="p-3 border border-[#8764A0] rounded-[25px] text-[#8764A0]"
          >
            <div className="flex gap-2 items-center">
              <div className="text-[15px] font-bold">Click Here to Upload</div>
              <IoIosCloudUpload />
            </div>
          </button>
        )}
        {/* Display selected files for upload */}
        {uploadedFiles.length > 0 && (
          <div className="mt-5">
            <h3 className="text-xl font-bold mb-2">Selected Files</h3>
            <ul>
              {uploadedFiles.map((file, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between p-2 border rounded-lg mb-2 bg-yellow-100"
                >
                  <span>{file.name}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveFile(index)}
                    className="bg-red-500 text-white px-3 py-1 rounded"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        {/* Display uploaded files from backend */}
        {employeeData.employee_upload_files &&
          employeeData.employee_upload_files.length > 0 && (
            <div className="mt-5">
              <h3 className="text-xl font-bold mb-2">Uploaded Files</h3>
              <ul>
                {employeeData.employee_upload_files.map((file, index) => (
                  <li key={index} className="mt-2">
                    <a
                      href={file.employee_upload_path}
                      download={file.original_file_name}
                      className="text-blue-500 underline"
                    >
                      {file.original_file_name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        {isEditable && (
          <div className="mt-5">
            <button
              type="submit"
              className="bg-[#8764A0] p-2 rounded-md text-white w-[150px]"
            >
              Submit
            </button>
            {successMessage && (
              <span className="ml-4 text-green-500">{successMessage}</span>
            )}
          </div>
        )}
      </form>

      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-80 z-50">
          <div className="bg-white rounded-lg p-8">
            <FileUpload
              setUploadedFiles={setUploadedFiles}
              uploadedFiles={uploadedFiles}
              onClose={handleCloseModal}
            />
            <button
              className="mt-4 bg-red-500 p-3 text-white py-2"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Emp_edit;
